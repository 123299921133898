<template>
  <div class="error">
    <div class="header"></div>
    <div class="body"></div>
    <div class="footer"></div>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {};
  },
  mounted: function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
